import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  const { right, left } = theme.mixins;
  return {
    searchContainer: {
      width: '100%',
      position: ' relative',

      '& .MuiBackdrop-root': {
        backgroundColor: 'inherit',
      },
      '& .MuiPaper-root.MuiDrawer-paper': {
        width: '100%',
        height: '100%',
        paddingTop: pxToRem(81),
        background: 'transparent',
        boxShadow: `0px 2px 15px 0px ${theme.mixins.Black(200)}`,
        [theme.breakpoints.up('lg')]: {
          paddingTop: 0,
          top: theme.spacing(16),
          overflowY: 'hidden',
          height: 'calc(100% - 8rem)',
          boxShadow: `inset 0px 2px 15px 0px ${theme.mixins.Black(200)}`,
        },
      },
    },
    closeIcon: {
      position: 'absolute',
      ...right(pxToRem(79)),
      top: '-1px',
      background: theme.mixins.White(1000),
      width: pxToRem(78),
      height: pxToRem(82),
      borderRight: `1px solid ${theme.mixins.Black(100)}`,
      borderLeft: `1px solid ${theme.mixins.Black(100)}`,
      zIndex: 1,
      cursor: 'pointer',
      transition: 'background-color 0.3s ease-in-out',
      display: 'none',

      [theme.breakpoints.up('lg')]: {
        borderRadius: borderRadius.circle,
        ...right(pxToRem(32)),
        top: pxToRem(32),
        width: pxToRem(48),
        height: pxToRem(48),
        padding: pxToRem(8),
        outline: 'none',
        border: '0',
        '&:hover': {
          backgroundColor: theme.mixins.Black(500),
          '& svg': {
            color: theme.mixins.White(),
          },
        },
        '&:focus': {
          backgroundColor: theme.mixins.Black(500),
          '& svg': {
            color: theme.mixins.White(),
          },
        },
        display: 'block',
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        top: pxToRem(28),
        ...right(pxToRem(25)),
        width: pxToRem(25),
        height: pxToRem(25),
        [theme.breakpoints.up('lg')]: {
          position: 'static',
          width: pxToRem(32),
          height: pxToRem(32),
        },
      },
    },
    searchBackground: {
      position: 'relative',
      background: theme.mixins.White(),
      boxShadow: `0px 0px 5px 0px ${theme.mixins.Black(200)}`,
      height: '100%',
      overflowY: 'auto',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        boxShadow: 'none',
        overflowY: 'hidden',
      },
    },
    leftImage: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        flexBasis: '23%',
        transition: 'all .3s ease-out',
      },
    },
    rightImage: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        position: 'relative',
        flexBasis: '100%',
        '& picture': {
          // position: 'absolute',
          // top: '0',
          // left: '0',
          // opacity: '0',
          // transition: 'opacity 0.3s ease-out',
          '&.animate': {
            opacity: '1',
          },
        },
        '& .mapImage': {
          position: 'absolute',
          ...theme.mixins.right(pxToRem(140)),
          top: '50%',
          ...theme.mixins.left('auto'),
          width: '50%',
          height: '50%',
          padding: '0',
          zIndex: '10',
          opacity: '1',
          transform: 'translateY(-50%)',
        },
      },
    },
    userActionContainer: {
      [theme.breakpoints.up('lg')]: {
        width: '36%',
        position: 'absolute',
        top: pxToRem(88),
        height: `calc(100% - ${theme.spacing(11)})`,
        overflowY: 'auto',
        boxShadow: 'none',
        ...left(pxToRem(92)),
        background: theme.mixins.White(1000),
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: pxToRem(8),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.mixins.SolidGrey(),
          borderRadius: borderRadius.b16Rem,
        },
      },
    },
  };
});

export default useStyles;
