import get from 'lodash/get';
import { getCurrentLanguage } from './getCurrentLanguage';

export const getCurrentPageUrl = props => {
  let currentLangVersionData;
  let url;
  const language = getCurrentLanguage(props) && getCurrentLanguage(props).toLowerCase();
  let pageLanguageVersions = get(props, 'sitecoreContext.pageLanguageVersions', []);
  if (pageLanguageVersions.length > 0) {
    currentLangVersionData = pageLanguageVersions.filter(item => (item.code && item.code.toLowerCase()) === language);
    if (currentLangVersionData && currentLangVersionData.length > 0) {
      url = currentLangVersionData[0].url;
    }
  }
  return url;
};
