import React, { useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import { SwipeableDrawer } from '@material-ui/core';

import Grid from '../../../components/molecules/Grid';
import { SEARCH_CONTAINER, USER_ACTION_CONTAINER } from './locators';
import DctSvgIcon from '../../atoms/Svg';
import Picture from '../../atoms/Picture';
import useStyles from './style';

const PopupWithBackground = forwardRef(function PopupWithBackground(props, ref) {
  const classes = useStyles();
  const {
    onCloseClick,
    shouldOpen,
    mobileImage,
    backgroundImage,
    backgroundColor,
    mapImage,
    anchor,
    disableLazyLoad = false,
    noRetina = false,
    closeIconAriaLabel,
    listImageArray,
    imageIndex = '',
    alternateAltText,
    isSearch = false,
    isMobile,
  } = props;

  const aspectRatios = {
    '3x2': {
      desktop: '3x2',
      mobile: '3x2',
    },
  };

  const [bgImage, setBgImage] = React.useState();

  useEffect(() => {
    setBgImage(backgroundImage);
  }, [backgroundImage]);

  useEffect(() => {
    if (shouldOpen) {
      setTimeout(() => {
        const searchBar = document.querySelector('#search_bar_popup');
        if (searchBar) {
          searchBar.focus();
        }
      }, 100);
    }
  }, [shouldOpen]);

  const bgPicRef = useRef(null),
    txtAreaRef = useRef(null),
    swipeableDrawerRef = useRef(null);
  useImperativeHandle(ref, () => ({
    txtAreaRef,
    bgPicRef,
    swipeableDrawerRef,
  }));

  const onPopOverCloseClick = () => {
    onCloseClick(false);
    if (!document) return;
    const headerEL = document.querySelector('header');
    const stickyNav = document.querySelector('#stickyBar');
    if (headerEL) {
      headerEL.classList.remove('fixed-header');
    }
    if (stickyNav) {
      stickyNav.classList.remove('header-added');
    }
  };

  const onPopOverCloseKey = event => {
    const code = event && (event.keyCode || event.which);
    if (code === 13) {
      //13 is the enter keycode
      onCloseClick(false);
      if (!document) return;
      const headerEL = document.querySelector('header');
      const stickyNav = document.querySelector('#stickyBar');
      if (headerEL) {
        headerEL.classList.remove('fixed-header');
      }
      if (stickyNav) {
        stickyNav.classList.remove('header-added');
      }
    }
  };

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={shouldOpen}
      onOpen={() => { }}
      onClose={onPopOverCloseClick}
      className={classes.searchContainer}
      data-locator={SEARCH_CONTAINER}
      transitionDuration={isSearch && isMobile ? { enter: 0, exit: 0 } : { enter: 350, exit: 350 }}
      ref={swipeableDrawerRef}
    >
      <Grid
        item
        className={classes.closeIcon}
        tabIndex="0"
        aria-label={(closeIconAriaLabel && closeIconAriaLabel.value) || 'close'}
        role="button"
        onClick={() => onPopOverCloseClick()}
        onKeyPress={e => onPopOverCloseKey(e)}
      >
        <DctSvgIcon name="CloseIcon" />
      </Grid>
      <Grid item className={classes.searchBackground}>
        <Grid item className={classes.rightImage}>
          {backgroundImage && (
            <Picture
              media={{
                image: bgImage,
                // it is not needed now but in future there might chances
                // we will need it
                mobileImage: mobileImage,
                aspectRatio: aspectRatios['3x2'],
                disableLazyLoad: disableLazyLoad,
                noRetina: noRetina,
              }}
              isParalax={false}
              ref={bgPicRef}
              className={
                (Number(imageIndex) === -1 || imageIndex === '') && 'animate'
              }
              alternateAltText={alternateAltText}
            />
          )}
          {listImageArray &&
            listImageArray.length > 0 &&
            listImageArray.map((item, i) => {
              return (
                <Picture
                  media={{
                    image: item,
                    // it is not needed now but in future there might chances
                    // we will need it
                    mobileImage: mobileImage,
                    aspectRatio: aspectRatios['3x2'],
                    disableLazyLoad: disableLazyLoad,
                    noRetina: noRetina,
                  }}
                  isParalax={false}
                  className={i === Number(imageIndex) && 'animate'}
                  key={i}
                  alternateAltText={alternateAltText}
                />
              );
            })}
          {mapImage && (
            <Picture
              media={{
                image: mapImage,
                mobileImage: mobileImage,
                aspectRatio: aspectRatios['3x2'],
                disableLazyLoad: disableLazyLoad,
                noRetina: noRetina,
              }}
              className="mapImage"
              alternateAltText={alternateAltText}
              isParalax={false}
            />
          )}
        </Grid>
        {props.children && (
          <Grid
            item
            className={classes.userActionContainer}
            data-locator={USER_ACTION_CONTAINER}
            ref={txtAreaRef}
          >
            {props.children}
          </Grid>
        )}
      </Grid>
    </SwipeableDrawer>
  );
});

export default PopupWithBackground;
