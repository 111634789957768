import get from 'lodash/get';

export const MegaNavList = [
  {
    values: {
      navTitle: {
        value: 'Things to do',
      },
      navSubTitle: {
        value: 'Things to do',
      },
      backgroundColor: {
        value: '#000',
      },
      itemUrl: '/en/things-to-do',
      mapImageUrl: null,
      imageUrl: {
        value: {
          src:
            'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
          '3x2': {
            large:
              'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
            large2x:
              'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
            medium:
              'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
            medium2x:
              'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
            small:
              'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
            small2x:
              'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
            xlarge:
              'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
          },
        },
      },
    },
    secondaryNavigation: [
      {
        values: {
          navTitle: {
            value: 'Water Activities',
          },
          navSubTitle: {
            value: 'Water Activities',
          },
          backgroundColor: {
            value: 'LightGrey',
          },
          itemUrl: '/en/things-to-do/water-activities',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src:
                'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
              '3x2': {
                large:
                  'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                large2x:
                  'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                medium:
                  'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                medium2x:
                  'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                small:
                  'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                small2x:
                  'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                xlarge:
                  'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
              },
            },
          },
        },
      },
      {
        values: {
          navTitle: {
            value: 'Sport Activities',
          },
          navSubTitle: {
            value: 'Sport Activities',
          },
          backgroundColor: {
            value: 'LightGrey',
          },
          itemUrl: '/en/things-to-do/sport-activities',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src:
                'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              '3x2': {
                large:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                large2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                xlarge:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              },
            },
          },
        },
      },
      {
        values: {
          navTitle: {
            value: 'Desert & Outdoors',
          },
          navSubTitle: {
            value: 'Desert & Outdoors',
          },
          backgroundColor: {
            value: 'LightGrey',
          },
          itemUrl: '/en/things-to-do/desert',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src:
                'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              '3x2': {
                large:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                large2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                xlarge:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              },
            },
          },
        },
      },
      {
        values: {
          navTitle: {
            value: 'Shopping',
          },
          navSubTitle: {
            value: 'Shopping',
          },
          backgroundColor: {
            value: 'LightGrey',
          },
          itemUrl: '/en/things-to-do/shopping',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src:
                'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              '3x2': {
                large:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                large2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                xlarge:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              },
            },
          },
        },
      },
      {
        values: {
          navTitle: {
            value: 'Itineraries',
          },
          navSubTitle: {
            value: 'Itineraries',
          },
          backgroundColor: {
            value: 'darkgray',
          },
          itemUrl: '/en/where-to-go/itineraries',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src:
                'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              '3x2': {
                large:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                large2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                medium2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                small2x:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                xlarge:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
              },
            },
          },
        },
      },
      {
        values: {
          navTitle: {
            value: 'Local Experiences',
          },
          navSubTitle: {
            value: 'Local Experiences',
          },
          backgroundColor: {
            value: 'LightGrey',
          },
          itemUrl: '/en/things-to-do/local-experiences',
          mapImageUrl: null,
          imageUrl: {
            value: {
              '3x2': {
                large:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                large2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                medium:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                medium2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                small:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                small2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                xlarge:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
              },
            },
          },
        },
      },
    ],
  },
  {
    values: {
      navTitle: {
        value: 'Where to go',
      },
      navSubTitle: {
        value: 'Explore',
      },
      backgroundColor: {
        value: 'yellow',
      },
      itemUrl: '/en/where-to-go',
      mapImageUrl: null,
      imageUrl: {
        value: {
          src:
            'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
          '3x2': {
            large:
              'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
            large2x:
              'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
            medium:
              'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
            medium2x:
              'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
            small:
              'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
            small2x:
              'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
            xlarge:
              'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
          },
        },
      },
    },
    secondaryNavigation: [
      {
        values: {
          navTitle: {
            value: 'Historical & Cultural attractions',
          },
          navSubTitle: {
            value: 'Historical & Cultural attractions',
          },
          backgroundColor: {
            value: 'red',
          },
          itemUrl: '/en/what-to-see/historical',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src: '/-/media/experience-explorer/presets/cecile-128x128.jpg',
              '3x2': {
                large:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                large2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                medium:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                medium2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                small:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                small2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                xlarge:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
              },
            },
          },
        },
      },
      {
        values: {
          navTitle: {
            value: 'Iconic landmarks',
          },
          navSubTitle: {
            value: 'Iconic landmarks',
          },
          backgroundColor: {
            value: 'LightGrey',
          },
          itemUrl: '/en/what-to-see/iconic-landmarks',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src: '/-/media/experience-explorer/presets/cecile-128x128.jpg',
              '3x2': {
                large:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                large2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                medium:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                medium2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                small:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                small2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                xlarge:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
              },
            },
          },
        },
        subNavigation: [
          {
            navTitle: {
              value: 'All Regions',
            },
            navSubTitle: {
              value: 'Al Ain',
            },
            backgroundColor: {
              value: 'darkgray',
            },
            itemUrl: '/en/where-to-go/by-region/abu-dhabi-city',
            mapImageUrl: {
              value: {
                src:
                  'https://image.shutterstock.com/image-vector/vector-map-united-arab-emirates-600w-408787291.jpg',
                '3x2': {
                  large:
                    'https://image.shutterstock.com/image-vector/vector-map-united-arab-emirates-600w-408787291.jpg',
                  large2x:
                    'https://image.shutterstock.com/image-vector/vector-map-united-arab-emirates-600w-408787291.jpg',
                  xlarge:
                    'https://image.shutterstock.com/image-vector/vector-map-united-arab-emirates-600w-408787291.jpg',
                },
              },
            },
            imageUrl: {
              value: {
                src:
                  'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                '3x2': {
                  large:
                    'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                  large2x:
                    'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                  medium:
                    'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                  medium2x:
                    'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                  small:
                    'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                  small2x:
                    'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                  xlarge:
                    'https://dev.vadsc.in/-/media/feature/platform/search/mask-copy-3_2.png?h=821&iar=0&w=1232',
                },
              },
            },
          },
          {
            navTitle: {
              value: 'Abu Dhabi City',
            },
            navSubTitle: {
              value: '',
            },
            backgroundColor: {
              value: '',
            },
            itemUrl: '/en/where-to-go/by-region/al-ain',
            mapImageUrl: {
              value: {
                src:
                  'https://image.shutterstock.com/image-vector/united-arab-emirates-map-on-600w-1031391583.jpg',
                '3x2': {
                  large:
                    'https://image.shutterstock.com/image-vector/united-arab-emirates-map-on-600w-1031391583.jpg',
                  large2x:
                    'https://image.shutterstock.com/image-vector/united-arab-emirates-map-on-600w-1031391583.jpg',
                  xlarge:
                    'https://image.shutterstock.com/image-vector/united-arab-emirates-map-on-600w-1031391583.jpg',
                },
              },
            },
            imageUrl: {
              value: {
                src: '/-/media/experience-explorer/presets/emilie-128x128.jpg',
                '3x2': {
                  large:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                  large2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                  medium:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                  medium2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                  small:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                  small2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                  xlarge:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
                },
              },
            },
          },
          {
            navTitle: {
              value: 'Abu Dhabi City',
            },
            navSubTitle: {
              value: '',
            },
            backgroundColor: {
              value: '',
            },
            itemUrl: '/en/where-to-go/by-region/al-dhafra-island',
            mapImageUrl: {
              value: {
                src:
                  '/-/media/experience-explorer/presets/christian-128x128.jpg',
                '3x2': {
                  large:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                  large2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                  xlarge:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
                },
              },
            },
            imageUrl: {
              value: {
                src: '/-/media/experience-explorer/presets/emilie-128x128.jpg',
                '3x2': {
                  large:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                  large2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                  medium:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                  medium2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                  small:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                  small2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                  xlarge:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
                },
              },
            },
          },
        ],
      },
      {
        values: {
          navTitle: {
            value: 'National attractions',
          },
          navSubTitle: {
            value: 'National attractions',
          },
          backgroundColor: {
            value: 'LightGrey',
          },
          itemUrl: '/en/what-to-see/national-attractions',
          mapImageUrl: null,
          imageUrl: {
            value: {
              src: '/-/media/experience-explorer/presets/cecile-128x128.jpg',
              '3x2': {
                large:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                large2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                medium:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                medium2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                small:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                small2x:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                xlarge:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
              },
            },
          },
        },
        subNavigation: [
          {
            navTitle: {
              value: 'Barouk',
            },
            navSubTitle: {
              value: '',
            },
            backgroundColor: {
              value: 'darkgray',
            },
            itemUrl: '/en/where-to-go/dinning-and-restaurants/barouk',
            mapImageUrl: null,
            imageUrl: {
              value: {
                src:
                  '/-/media/project/vad/dinnings/barouk/gellery/video-background-3-cropped.jpg',
                '3x2': {
                  large:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                  large2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                  medium:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                  medium2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                  small:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                  small2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                  xlarge:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
                },
              },
            },
          },
          {
            navTitle: {
              value: 'Mijana',
            },
            navSubTitle: {
              value: '',
            },
            backgroundColor: {
              value: 'LightGrey',
            },
            itemUrl: '/en/where-to-go/dinning-and-restaurants/mijana',
            mapImageUrl: null,
            imageUrl: {
              value: {
                src:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                '3x2': {
                  large:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                  large2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                  medium:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                  medium2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                  small:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                  small2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                  xlarge:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
                },
              },
            },
          },
          {
            navTitle: {
              value: 'Rosewater',
            },
            navSubTitle: {
              value: '',
            },
            backgroundColor: {
              value: 'darkgray',
            },
            itemUrl: '/en/where-to-go/dinning-and-restaurants/rosewater',
            mapImageUrl: null,
            imageUrl: {
              value: {
                src:
                  '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
                '3x2': {
                  large:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
                  large2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
                  medium:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
                  medium2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
                  small:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
                  small2x:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
                  xlarge:
                    '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
                },
              },
            },
          },
        ],
      },
    ],
  },
  {
    values: {
      navTitle: {
        value: 'Events',
      },
      navSubTitle: {
        value: 'Things to do',
      },
      backgroundColor: {
        value: '#000',
      },
      itemUrl: '/en/Events',
      mapImageUrl: null,
      imageUrl: {
        value: {
          src:
            'https://dev.vadsc.in/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg',
          '3x2': {
            large:
              '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1680&ch=940&hash=C4B7823FC4C12FB52401DDE084100CBA',
            large2x:
              '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=3360&ch=1881&hash=85F4CC07099729A3CA33F78F3946DBC5',
            medium:
              '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1024&ch=573&hash=B0BA293EAF2AA5EF11297125A007DB2E',
            medium2x:
              '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2048&ch=1146&hash=DF32EEA5EFA692BEA34C5271F24AF02E',
            small:
              '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=768&ch=430&hash=6FF1589F6EBB4BB14440D9DB4E75B877',
            small2x:
              '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=1536&ch=860&hash=0F814A7896E615ABD24077AA94ABFFDB',
            xlarge:
              '/-/media/project/vad/dinnings/barouk/gellery/webpnetresizeimage.jpg?cx=0.2&cy=0.88&cw=2500&ch=1400&hash=08E499A06881BE89CCC103C7609DB5E1',
          },
        },
      },
    },
    secondaryNavigation: [],
  },
];

const l2List = items => {
  const x = [];

  items &&
    items.length > 0 &&
    items.forEach(secNav => {
      x.push({
        values: {
          navTitle: get(secNav, 'navTitle', ''),
          navSubTitle: get(secNav, 'navSubTitle', ''),
          imageUrl: get(secNav, 'imageUrl', ''),
          backgroundColor: get(secNav, 'backgroundColor.fields.colorCode', ''),
          itemUrl: {
            value: {
              href: get(secNav, 'itemUrl', ''),
            },
          },
          mapImageUrl: get(secNav, 'mapImageUrl', ''),
        },
      });
    });
  return x;
};

const l1List = items => {
  const x = [];
  items &&
    items.length > 0 &&
    items.forEach(secNav => {
      x.push({
        values: {
          navTitle: get(secNav, 'values.navTitle', ''),
          navSubTitle: get(secNav, 'values.navSubTitle', ''),
          imageUrl: get(secNav, 'values.imageUrl', ''),
          backgroundColor: get(
            secNav,
            'values.backgroundColor.fields.colorCode',
            ''
          ),
          itemUrl: {
            value: {
              href: get(secNav, 'values.itemUrl', ''),
            },
          },
          mapImageUrl: get(secNav, 'values.mapImageUrl', ''),
        },
        subNavigation: l2List(secNav && secNav.subNavigation),
        enableBackgroundImage: get(secNav, 'enableBackgroundImage', ''),
      });
    });
  return x;
};

export const getProcessedData = fields => {
  const preProcessList = [];
  fields &&
    fields.length > 0 &&
    fields.forEach(item => {
      preProcessList.push({
        fields: {
          values: {
            navTitle: get(item, 'values.navTitle', ''),
            navSubTitle: get(item, 'values.navSubTitle', ''),
            imageUrl: get(item, 'values.imageUrl', ''),
            backgroundColor: get(
              item,
              'values.backgroundColor.fields.colorCode',
              ''
            ),
            itemUrl: {
              value: {
                href: get(item, 'values.itemUrl', ''),
              },
            },
            mapImageUrl: get(item, 'values.mapImageUrl', ''),
          },
          secondaryNavigation: l1List(item && item.secondaryNavigation),
        },
      });
    });

  return preProcessList;
};
